<template>
  <div class="bg-white p-20 round">
    <div class="container mx-auto text-left">
      <div v-if="hasPremission && loaded" class="mb-40">
        <div v-if="edit">
          <policyForm :dataSource="detail">
            <div class="text-center mt-40" slot-scope="{ validate }">
              <a-space>
                <a-button
                  @click="edit = false"
                  type="primary"
                  v-if="detail.AuditStatus === 3 && edit"
                  >取消</a-button
                >
                <a-button
                  @click="submit(validate)"
                  type="primary"
                  v-if="detail.AuditStatus === 3 && edit"
                  >提交</a-button
                >
              </a-space>
            </div>
          </policyForm>
        </div>
        <div v-else>
          <div
            style="font-size: 32px; line-height: 40px; word-wrap: break-word"
            class="text-bold mb-10"
          >
            {{ detail.Title }}
          </div>
          <div class="mb-40">
            <a-space class="text-xs text-999">
              <span>
                <span>来源：</span>
                <span>{{ detail.Source }}</span>
              </span>
              <span>|</span>
              <span>
                {{ detail.Region.Name }}
              </span>
              <span>|</span>
              <span>
                {{ detail.Type.Name }}
              </span>
              <span>|</span>
              <span>
                {{ detail.PublicTime | formatterTime("YYYY-MM-DD") }}
              </span>
            </a-space>
          </div>
          <div v-html="detail.Desc"></div>
          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold">附件</span>
          </div>
          <div class="p-20 inline-block bg-f9">
            <div class="inline-block bg-f9">
              <div
                class="mb-10"
                v-for="item in detail.Attachments"
                :key="item.ID"
              >
                <div
                  class="relative flex items-center"
                  style="padding-right: 80px"
                >
                  <img
                    class="mr-10"
                    src="@/assets/icons/attachment.svg"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                  <div
                    class="absolute right-0 top-0 cursor-pointer text-primary"
                    @click="download(item)"
                  >
                    下载
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="userInfo.policy_privilege && detail.AuditStatus === 2">
          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold"
              >参与的响应方（{{ participantsTotal }}个）</span
            >
          </div>

          <a-row :gutter="20">
            <a-col
              :span="4"
              class="text-center mb-20"
              v-for="item in participants"
              :key="item.ID"
            >
              <div
                class="border border-eee hover:shadow p-10 relative"
                @click="select(item.ID)"
              >
                <a-checkbox
                  v-if="!participantsTotal1"
                  :checked="selected.includes(item.ID)"
                  class="absolute top-10 right-10"
                ></a-checkbox>
                <div
                  class="
                    w-40
                    h-40
                    mb-10
                    mx-auto
                    rounded-full
                    overflow-hidden
                    border border-eee
                  "
                >
                  <img
                    v-if="item.Company.Info.Logo"
                    :src="getFileUrl(item.Company.Info.Logo.ID)"
                    alt=""
                  />
                </div>
                <div class="text-sm ellipsis-2 mb-20" style="height: 40px">
                  {{ item.Company.Info.Name }}
                </div>
                <a-popover>
                  <div class="text-xs text-999 cursor-pointer">下载附件</div>
                  <div class="p-20 inline-block bg-f9" slot="content">
                    <div class="inline-block bg-f9">
                      <div
                        class="mb-10"
                        v-for="item in item.Attachments"
                        :key="item.ID"
                      >
                        <div
                          class="relative flex items-center"
                          style="padding-right: 80px"
                        >
                          <img
                            class="mr-10"
                            src="@/assets/icons/attachment.svg"
                            alt=""
                          />
                          <span>{{ item.Name }}</span>
                          <div
                            class="
                              absolute
                              right-0
                              top-0
                              cursor-pointer
                              text-primary
                            "
                            @click="download(item)"
                          >
                            下载
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </a-col>
          </a-row>
        </div>
        <div
          v-if="
            userInfo.policy_privilege &&
            detail.AuditStatus === 2 &&
            participantsTotal
          "
        >
          <div class="text-lg font-bold mt-40 mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold"
              >采纳的响应方（{{ participantsTotal1 }}个）</span
            >
          </div>

          <a-row :gutter="20">
            <a-col
              :span="4"
              class="text-center mb-20"
              v-for="item in participants1"
              :key="item.ID"
            >
              <div
                class="border border-eee hover:shadow p-10 relative"
                @click="select(item.ID)"
              >
                <a-checkbox
                  v-if="!participantsTotal1"
                  :checked="selected.includes(item.ID)"
                  class="absolute top-10 right-10"
                ></a-checkbox>
                <div
                  class="
                    w-40
                    h-40
                    mb-10
                    mx-auto
                    rounded-full
                    overflow-hidden
                    border border-eee
                  "
                >
                  <img
                    v-if="item.Company.Info.Logo"
                    :src="getFileUrl(item.Company.Info.Logo.ID)"
                    alt=""
                  />
                </div>
                <div class="text-sm ellipsis-2 mb-20" style="height: 40px">
                  {{ item.Company.Info.Name }}
                </div>
                <a-popover>
                  <div class="text-xs text-999 cursor-pointer">下载附件</div>
                  <div class="p-20 inline-block bg-f9" slot="content">
                    <div class="inline-block bg-f9">
                      <div
                        class="mb-10"
                        v-for="item in item.Attachments"
                        :key="item.ID"
                      >
                        <div
                          class="relative flex items-center"
                          style="padding-right: 80px"
                        >
                          <img
                            class="mr-10"
                            src="@/assets/icons/attachment.svg"
                            alt=""
                          />
                          <span>{{ item.Name }}</span>
                          <div
                            class="
                              absolute
                              right-0
                              top-0
                              cursor-pointer
                              text-primary
                            "
                            @click="download(item)"
                          >
                            下载
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-popover>
              </div>
            </a-col>
          </a-row>
        </div>
        <div
          class="mt-40 mb-20"
          v-if="!userInfo.policy_privilege && attachments1.length"
        >
          <div class="text-lg font-bold mb-20">
            <span class="mr-10 text-primary align-middle">|</span>
            <span class="align-middle text-111 font-bold mr-20">上传附件</span>
          </div>

          <div class="p-20 inline-block bg-f9">
            <div class="inline-block bg-f9">
              <div class="mb-10" v-for="item in attachments1" :key="item.ID">
                <div
                  class="relative flex items-center"
                  style="padding-right: 80px"
                >
                  <img
                    class="mr-10"
                    src="@/assets/icons/attachment.svg"
                    alt=""
                  />
                  <span>{{ item.name }}</span>
                  <div
                    class="absolute right-0 top-0 cursor-pointer text-primary"
                    @click="download(item)"
                  >
                    下载
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- <div class="text-lg font-bold mt-20 mb-20">
          <span class="mr-10 text-primary align-middle">|</span>
          <span class="align-middle text-111 font-bold">采纳的响应方</span>
        </div>

        <a-row :gutter="20">
          <a-col :span="4" class="p-10 text-center">
            <div class="w-40 h-40 mb-10 mx-auto"></div>
            <div class="text-sm ellipsis-2"></div>
            <div class="text-xs text-999">下载附件</div>
          </a-col>
        </a-row> -->
      </div>
      <div v-else-if="loaded && !hasPremission">
        <div
          class="flex flex-col justify-center items-center"
          style="height: calc(100vh - 72px)"
        >
          <img src="@/assets/images/noPromission.svg" alt="" />
          <div class="text-999 text-sm">抱歉，您没有权限访问该页面</div>
        </div>
      </div>
      <div class="text-center mt-40">
        <a-space v-if="userInfo.policy_privilege">
          <a-button
            @click="confirm"
            type="primary"
            v-if="
              detail.EvaluationStatus === 0 &&
              detail.AuditStatus === 2 &&
              participantsTotal
            "
            >采纳</a-button
          >
          <a-button
            @click="edit = true"
            type="primary"
            v-else-if="detail.AuditStatus === 3 && !edit"
            >重新编辑</a-button
          >
        </a-space>
      </div>
    </div>
  </div>
</template>

<script>
import {
  getPolicy,
  getParticipants,
  getPublicPolicy,
  getMyParticipants,
  submitPolicy,
  finishPolicy,
  updatePolicy,
} from "@/api/api/policy";
import { getFileUrl } from "@/api/api/common";
import { download } from "@/api";
import { transformAttachment } from "@/utils/transform/_util.js";
import policyForm from "../../policy/components/policyForm.vue";
export default {
  components: { policyForm },
  data() {
    return {
      id: "",
      hasPremission: true,
      loaded: false,
      detail: {},
      visible: false,
      status: 0,
      edit: false,
      participants: [],
      participantsTotal: 0,
      participants1: [],
      participantsTotal1: 0,
      attachments1: [],
      selected: [],
    };
  },
  created() {
    this.id = this.$route.params.id;
    this.getPolicy(this.id);
    this.getParticipants();
    this.getParticipants1();
    this.getMyParticipants();
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo;
    },
  },
  methods: {
    getFileUrl(id) {
      return getFileUrl(id);
    },
    getPolicy(id) {
      if (this.userInfo.policy_privilege) {
        getPolicy(id)
          .then((data) => {
            this.detail = data;
          })
          .catch(({ code }) => {
            if (code === 403) {
              this.hasPremission = false;
            }
          })
          .finally(() => {
            this.loaded = true;
          });
      } else {
        getPublicPolicy(id)
          .then((data) => {
            this.detail = data;
          })
          .catch(({ code }) => {
            if (code === 403) {
              this.hasPremission = false;
            }
          })
          .finally(() => {
            this.loaded = true;
          });
      }
    },
    reedit() {},
    getParticipants() {
      getParticipants(this.id)
        .then(({ data }) => {
          this.participantsTotal = data.count;
          this.participants = data.rows;
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
    getParticipants1() {
      getParticipants(this.id, { selected: true })
        .then(({ data }) => {
          this.participantsTotal1 = data.count;
          this.participants1 = data.rows;
        })
        .catch(() => {
          this.$message.error("获取列表失败");
        });
    },
    download(item) {
      download(item.ID).then((res) => {
        var reader = new FileReader();
        reader.readAsDataURL(res);
        reader.onload = function (e) {
          const a = document.createElement("a");
          a.download = item.Name;
          a.href = e.target.result;
          a.click();
        };
      });
    },
    getMyParticipants() {
      getMyParticipants(this.id)
        .then(({ data }) => {
          this.attachments1 = transformAttachment(data.Attachments) || [];
        })
        .catch(() => {
          this.attachments1 = [];
        });
    },
    submit(validate) {
      validate().then((res) => {
        updatePolicy(this.id, res).then(() => {
          submitPolicy(this.id)
            .then(() => {
              this.$message.success("提交成功");
              this.edit = false;
              this.getPolicy(this.id);
            })
            .catch(() => {
              this.$message.error("提交失败");
            });
        });
      });
    },
    confirm() {
      if (!this.selected.length) {
        this.$message.warn("请先选择响应方");
      }
      finishPolicy(this.id, { Participants: this.selected })
        .then(() => {
          this.$message.success("采纳成功");
          this.getPolicy(this.id);
          this.getParticipants();
          this.getParticipants1();
          this.getMyParticipants();
        })
        .catch(() => {
          this.$message.error("采纳失败");
        });
    },
    select(id) {
      if (this.selected.includes(id)) {
        for (let i = 0; i < this.selected.length; i++) {
          if (this.selected[i] === id) {
            this.selected.splice(i, 1);
            break;
          }
        }
      } else {
        this.selected.push(id);
      }
    },
  },
};
</script>